import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import BotaoDenunciaPerfil from '../Components/BotaoDenunciaPerfil';
import GuiaAjudaDocument from '../documents/GuiaAjudaDocument'; // Importa o componente GuiaAjudaDocument

interface ModalMenuOpcoesProps {
  isOpen: boolean;
  onClose: () => void;
  isOwner: boolean;
  itemId: number | string;
  tipoItem: 'usuario' | 'banda';
}

const ModalMenuOpcoes: React.FC<ModalMenuOpcoesProps> = ({
  isOpen,
  onClose,
  isOwner,
  itemId,
  tipoItem,
}) => {
  const navigate = useNavigate();
  const { state: authState, logout } = useContext(AuthContext);
  const [isHelpOpen, setIsHelpOpen] = useState(false); // Estado para o modal de ajuda

  const handleLogout = async () => {
    try {
      await logout();
      onClose();
      navigate('/PrimeiraTela');
    } catch (error) {
      console.error('Falha ao fazer logout:', error);
    }
  };

  const handleEditProfile = () => {
    onClose();
    if (authState.id_usuario) {
      navigate('/UserEditPerfil');
    } else if (authState.id_banda) {
      navigate('/BandEditPerfil');
    } else {
      console.error('Nenhum ID de usuário ou banda encontrado');
    }
  };

  const handleDashboard = () => {
    onClose();
    if (authState.id_banda) {
      navigate(`/DashBoardBand/${authState.id_banda}`);
    } else {
      console.error('ID da banda não encontrado');
    }
  };

  const handleDenunciaEnviada = () => {
    onClose();
  };

  const handleOpenHelp = () => {
    setIsHelpOpen(true);
  };

  const handleCloseHelp = () => {
    setIsHelpOpen(false);
  };

  if (!isOpen) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <button style={styles.closeButton} onClick={onClose}>Fechar</button>
        <h2 style={styles.title}>Opções do Perfil</h2>
        <div style={styles.optionsList}>
          {isOwner ? (
            <>
              <button style={styles.optionButton} onClick={handleEditProfile}>Editar Perfil</button>
              {authState.id_banda && (
                <button style={styles.optionButton} onClick={handleDashboard}>Dashboard</button>
              )}
              <button style={styles.optionButton} onClick={handleLogout}>Sair</button>
            </>
          ) : (
            <BotaoDenunciaPerfil
              itemId={itemId}
              tipoItem={tipoItem}
              onDenunciaEnviada={handleDenunciaEnviada}
            />
          )}
          <button style={styles.optionButton} onClick={handleOpenHelp}>Guia de Ajuda</button>
        </div>

        {/* Exibe o modal de ajuda quando isHelpOpen estiver verdadeiro */}
        {isHelpOpen && (
          <div style={styles.helpOverlay} onClick={handleCloseHelp}>
            <div style={styles.helpModal}>
              <button style={styles.closeButton} onClick={handleCloseHelp}>Fechar</button>
              <GuiaAjudaDocument />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  } as React.CSSProperties,
  modal: {
    backgroundColor: '#202020',
    padding: '20px',
    width: '60%',
    maxHeight: '80%',
    overflowY: 'auto',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    position: 'relative',
    zIndex: 1000,
  } as React.CSSProperties,
  closeButton: {
    alignSelf: 'flex-end',
    backgroundColor: 'transparent',
    border: 'none',
    color: 'white',
    fontSize: '16px',
    cursor: 'pointer',
  } as React.CSSProperties,
  title: {
    color: 'white',
    fontSize: '24px',
    margin: '0 0 20px 0',
  } as React.CSSProperties,
  optionsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  } as React.CSSProperties,
  optionButton: {
    backgroundColor: '#914100',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '10px',
    cursor: 'pointer',
    fontSize: '16px',
  } as React.CSSProperties,
  helpOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1001,
  } as React.CSSProperties,
  helpModal: {
    backgroundColor: '#f0f0f0',
    padding: '20px',
    borderRadius: '10px',
    width: '60%',
    maxHeight: '80%',
    overflowY: 'auto',
  } as React.CSSProperties,
};

export default ModalMenuOpcoes;
