import React, { useEffect, useState } from 'react';
import axios from 'axios';

interface Music {
  id_musica: number;
  id_banda: number;
  id_album: number;
  nome_musica: string;
  compositor: string;
  ano_lancamento: number;
  url_musica: string;
  url_imagem: string;
  duracao: string;
  data_adicao: string;
  letra: string;
  status: string;
}

const Admin158492: React.FC = () => {
  const [musicas, setMusicas] = useState<{ pendente: Music[]; aprovado: Music[]; reprovado: Music[] }>({
    pendente: [],
    aprovado: [],
    reprovado: [],
  });
  const [expandedStatus, setExpandedStatus] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchMusics();
  }, []);

  const fetchMusics = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get('https://tribhus.shop:5000/admin/musics');
      setMusicas(response.data);
    } catch (err) {
      setError('Erro ao carregar músicas.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const updateMusicStatus = async (id_musica: number, status: string) => {
    try {
      const response = await axios.put(
        `https://tribhus.shop:5000/admin/update/${id_musica}/status`,
        { status }
      );

      if (response.status === 200) {
        alert(`Status da música atualizado para "${status}" com sucesso!`);
        fetchMusics(); // Atualiza a lista após a mudança de status
      } else {
        alert('Falha ao atualizar status da música.');
      }
    } catch (err) {
      console.error('Erro ao atualizar status:', err);
      alert('Erro ao atualizar status da música. Verifique sua conexão e tente novamente.');
    }
  };

  const toggleStatus = (status: string) => {
    setExpandedStatus(expandedStatus === status ? null : status);
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Gerenciamento de Músicas</h1>
      {loading && <p style={styles.message}>Carregando...</p>}
      {error && <p style={{ ...styles.message, color: '#dc3545' }}>{error}</p>}

      {!loading && (
        <div>
          {(['pendente', 'aprovado', 'reprovado'] as const).map((status) => (
            <div key={status} style={styles.statusSection}>
              <button
                style={{
                  ...styles.statusButton,
                  backgroundColor: expandedStatus === status ? '#ff6600' : '#f2f2f2',
                  color: expandedStatus === status ? 'white' : '#333',
                }}
                onClick={() => toggleStatus(status)}
              >
                {status.charAt(0).toUpperCase() + status.slice(1)} ({musicas[status]?.length || 0})
              </button>
              {expandedStatus === status && (
                <div style={styles.musicList}>
                  {(musicas[status] || []).map((music) => (
                    <div key={music.id_musica} style={styles.musicCard}>
                      <div style={styles.musicInfo}>
                        <img
                          src={music.url_imagem}
                          alt={music.nome_musica}
                          style={styles.musicImage}
                        />
                        <div>
                          <h3 style={styles.musicTitle}>{music.nome_musica}</h3>
                          <p style={styles.musicSubtitle}>
                            {music.compositor || 'Desconhecido'} - {music.ano_lancamento || 'Ano não informado'}
                          </p>
                        </div>
                      </div>
                      <div style={styles.actions}>
                        {status === 'pendente' && (
                          <>
                            <button
                              style={{ ...styles.actionButton, backgroundColor: '#28a745' }}
                              onClick={() => updateMusicStatus(music.id_musica, 'aprovado')}
                            >
                              Aprovar
                            </button>
                            <button
                              style={{ ...styles.actionButton, backgroundColor: '#dc3545' }}
                              onClick={() => updateMusicStatus(music.id_musica, 'reprovado')}
                            >
                              Reprovar
                            </button>
                          </>
                        )}
                        <audio controls style={styles.audioPlayer}>
                          <source src={music.url_musica} type="audio/mpeg" />
                          Seu navegador não suporta o player de áudio.
                        </audio>
                      </div>
                    </div>
                  ))}
                  {(musicas[status] || []).length === 0 && (
                    <p style={styles.message}>Nenhuma música encontrada.</p>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  title: {
    fontSize: '24px',
    color: '#333',
    textAlign: 'center' as 'center',
    marginBottom: '20px',
  },
  message: {
    fontSize: '16px',
    textAlign: 'center' as 'center',
    color: '#555',
  },
  statusSection: {
    marginBottom: '20px',
  },
  statusButton: {
    width: '100%',
    padding: '10px',
    textAlign: 'left' as 'left',
    fontSize: '18px',
    fontWeight: 'bold',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginBottom: '10px',
    transition: 'background-color 0.3s ease',
  },
  musicList: {
    paddingLeft: '10px',
  },
  musicCard: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    marginBottom: '10px',
    backgroundColor: 'white',
    borderRadius: '4px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  musicInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  musicImage: {
    width: '50px',
    height: '50px',
    borderRadius: '4px',
    marginRight: '10px',
  },
  musicTitle: {
    fontSize: '16px',
    color: '#333',
    marginBottom: '5px',
  },
  musicSubtitle: {
    fontSize: '14px',
    color: '#777',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  actionButton: {
    padding: '8px 12px',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
    transition: 'background-color 0.3s ease',
  },
  audioPlayer: {
    width: '300px', // Ajuste a largura do player aqui
    maxWidth: '100%', // Garante que o player não ultrapasse o tamanho do contêiner
  },
};

export default Admin158492;
